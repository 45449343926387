// eslint-disable-next-line consistent-return
(() => {
  // If not IE
  if (typeof window.CustomEvent === 'function') return false;
  const CustomEvent = (event, params) => {
    // eslint-disable-next-line no-param-reassign
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();
