import '../../../utils/customEvent';

import './style.scss';

function ToggleNav() {
  const header = document.querySelector('Header');
  const button = header.querySelector('.Header__burger');
  const nav = header.querySelector('.Header__menu');

  if (!header && !button && !nav) return;

  this.state = {
    isOpen: false,
  };
  this.sel = nav;
  const showEvent = new CustomEvent('show');

  this.show = () => {
    if (this.state.isOpen) return;

    this.state.isOpen = true;
    header.classList.add('Header--open');
    this.sel.dispatchEvent(showEvent);
  };

  this.hide = () => {
    if (!this.state.isOpen) return;

    this.state.isOpen = false;
    header.classList.remove('Header--open');
  };

  button.addEventListener('click', () => (this.state.isOpen ? this.hide() : this.show()), false);

  window.addEventListener('orientationchange', this.hide);
}

window.addEventListener('DOMContentLoaded', () => {
  const toggleNav = new ToggleNav();

  toggleNav.sel.addEventListener('show', () => {});

  const servicesMenuBtn = document.querySelector('.Header__submenu__label');
  const servicesMenu = document.querySelector('.Header__submenu');

  if (servicesMenuBtn && window.innerWidth <= 1024) {
    servicesMenuBtn.addEventListener('click', () => servicesMenu.classList.toggle('open'));
  }
});

window.addEventListener(
  'scroll',
  () => {
    const header = document.querySelector('.Header');

    if (!header) return;

    if (window.scrollY > 0) {
      header.classList.add('Header--scrolled');
    } else {
      header.classList.remove('Header--scrolled');
    }
  },
  false,
);
