const formatDate = date => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let formattedDate = new Date(date * 1000);
  formattedDate = `${formattedDate.getDate()} ${
    monthNames[formattedDate.getMonth()]
  } ${formattedDate.getFullYear()}`;

  return formattedDate;
};

export default formatDate;
